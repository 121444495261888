import React from "react";
import { graphql } from "gatsby";
import SitemapData from "../components/sitemapData";
import { SEO } from "../components/Base/Seo";

// Define fragments
export const productFragment = graphql`
  fragment ProductFragment on WpSimpleProduct {
    id
    name
    slug
  }
`;

export const productCategoryFragment = graphql`
  fragment ProductCategoryFragment on WpProductCategory {
    id
    name
    slug
  }
`;

export const productBrandFragment = graphql`
  fragment ProductBrandFragment on WpProductTag {
    id
    name
    slug
  }
`;

export const postFragment = graphql`
  fragment PostFragment on WpPost {
    id
    slug
    title
  }
`;

export const tagFragment = graphql`
  fragment TagFragment on WpCategory {
    id
    slug
    name
  }
`;

// Define the main page component
const Sitemap = ({ data }: any) => {
  return <SitemapData data={data} />;
};

// Define the main page query
export const query = graphql`
  query {
    allSitePage {
      nodes {
        id
        path
      }
    }
    allWpSimpleProduct(sort: { order: ASC, fields: name }) {
      nodes {
        ...ProductFragment
      }
    }
    allWpProductCategory(
      sort: { order: ASC, fields: display }
      filter: {
        slug: {
          nin: [
            "bestselling-products"
            "medical-devices"
            "recomended-products"
            "uncategorized"
          ]
        }
      }
    ) {
      nodes {
        ...ProductCategoryFragment
      }
    }
    allWpProductTag(
      sort: { order: ASC, fields: name }
      filter: { slug: { nin: "medical-devices" } }
    ) {
      nodes {
        ...ProductBrandFragment
      }
    }
    allWpPost(sort: { order: DESC, fields: date }) {
      nodes {
        ...PostFragment
      }
    }
    allWpCategory(
      sort: { order: ASC, fields: name }
      filter: { slug: { nin: "uncategorized" } }
    ) {
      nodes {
        ...TagFragment
      }
    }
  }
`;

export const Head = () => (
  <SEO
    title="Site Map - Doctor Medica"
    description="Easily navigate through our website with our sitemap, offering a structured overview of all our pages. Find what you're looking for quickly and efficiently."
  />
);

export default Sitemap;
