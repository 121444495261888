import '../pages/sitemap.css';

import { Box, Container } from '@chakra-ui/react';
import { brandsToExclude, categoriesToExclude } from '../queries/toExclude';

import Layout from './Base/Layout';
import { Link } from 'gatsby';
import React from 'react';

const isBrandExcluded = (productName: any) => {
	const normalizedProductName = productName.toUpperCase();
	return brandsToExclude.some((brand) => normalizedProductName.includes(brand));
};

const isCategoryExcluded = (category: any) => {
	return categoriesToExclude.includes(category.name);
};

const SitemapData = ({ data }: any) => {
	return (
		<Layout>
			<Container
				py='40px'
				maxW='1240px'
				mx='auto'>
				<h1 style={{ fontSize: '36px', paddingBottom: '24px' }}>Site map</h1>

				<Box
					display='flex'
					flexDirection={{ base: 'column', md: 'row' }}
					flex={1}
					paddingBottom='10px'>
					<div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
						<section>
							<h2
								style={{
									fontSize: '16px',
									fontWeight: '500',
									paddingBottom: '8px',
									paddingTop: '10px',
								}}>
								About DoctorMedica
							</h2>
							<ul
								className='sitemapUl'
								style={{
									listStyleType: 'none',
									color: '#D28C49',
									fontSize: '18px',
								}}>
								<li>
									<a href='https://www.doctormedica.co'>Home</a>
								</li>
								<li>
									<a href='https://www.doctormedica.co/about-us'>About Us</a>
								</li>
								<li>
									<a href='https://www.doctormedica.co/blog'>Blog</a>
								</li>
								<li>
									<a href='https://www.doctormedica.co/contact-us'>Contact</a>
								</li>
								<li>
									<a href='https://www.doctormedica.co/privacy-policy'>
										Privacy Policy
									</a>
								</li>
								<li>
									<a href='https://www.doctormedica.co/shipping-return-policy'>
										Shipping Policy
									</a>
								</li>
								<li>
									<a href='https://www.doctormedica.co/password-reset'>
										Reset Password
									</a>
								</li>
								<li>
									<a href='https://www.doctormedica.co/customer-agreement'>
										Customer Agreement
									</a>
								</li>
								<li>
									<a href='https://www.doctormedica.co/use-policy'>
										Terms and Conditions
									</a>
								</li>
								<li>
									<a href='https://www.doctormedica.co/faq'>FAQ</a>
								</li>
								<li>
									<a href='https://www.doctormedica.co/sign-in'>Sign In</a>
								</li>
								<li>
									<a href='https://www.doctormedica.co/shop'>Shop</a>
								</li>
								<li>
									<a href='https://www.doctormedica.co/registration'>
										Registration
									</a>
								</li>
								<li>
									<a href='https://www.doctormedica.co/how-to-order'>
										How to Order
									</a>
								</li>
								<li>
									<a href='https://www.doctormedica.co/referral-program'>
										Referral Program
									</a>
								</li>
								<li>
									<a href='https://www.doctormedica.co/promotions'>
										Promotions
									</a>
								</li>
								<li>
									<a href='https://www.doctormedica.co/loyalty-program'>
										Loyalty Program
									</a>
								</li>
							</ul>
						</section>
					</div>
					<div style={{ display: 'flex', flex: 1 }}>
						<section>
							<h2
								style={{
									fontSize: '16px',
									fontWeight: '500',
									paddingBottom: '8px',
									paddingTop: '10px',
								}}>
								Categories
							</h2>
							<ul
								className='sitemapUl'
								style={{
									listStyleType: 'none',
									color: '#D28C49',
									fontSize: '18px',
								}}>
								{data.allWpProductCategory.nodes
									.filter((category: any) => !isCategoryExcluded(category))
									.map((category: any) => (
										<li key={category.id}>
											<a href={`/category/${category.slug}`}>{category.name}</a>
										</li>
									))}
							</ul>
						</section>
					</div>

					<div style={{ display: 'flex', flex: 1 }}>
						<section>
							<h2
								style={{
									fontSize: '16px',
									fontWeight: '500',
									paddingBottom: '8px',
									paddingTop: '10px',
								}}>
								Brands
							</h2>
							<ul
								className='sitemapUl'
								style={{
									listStyleType: 'none',
									color: '#D28C49',
									fontSize: '18px',
								}}>
								{data.allWpProductTag.nodes
									.filter((tag: any) => !isBrandExcluded(tag.name)) // Assuming you have a field named 'name' in your tag nodes
									.map((tag: any) => (
										<li key={tag.id}>
											<a href={`/shop/brand/${tag.slug}`}>{tag.name}</a>
										</li>
									))}
							</ul>
						</section>
					</div>
				</Box>

				<h2
					style={{
						borderTop: '1px solid #E2E8F0',
						paddingTop: '10px',
						fontSize: '16px',
						fontWeight: '500',
						paddingBottom: '8px',
					}}>
					Products
				</h2>
				<ul className='productList'>
					<li style={{ paddingBottom: '25px' }}>
						{data.allWpSimpleProduct.nodes
							.filter((product: any) => !isBrandExcluded(product.name))
							.map((product: any) => (
								<section>
									<ul
										className='sitemapUl'
										style={{
											listStyleType: 'none',
											color: '#D28C49',
											fontSize: '18px',
										}}>
										<li key={product.id}>
											<a href={`/${product.slug}`}>{product.name}</a>
										</li>
									</ul>
								</section>
							))}
					</li>
				</ul>

				<ul className='blogList'>
					<section>
						<h2
							style={{
								fontSize: '16px',
								fontWeight: '500',
								paddingBottom: '8px',
							}}>
							Blog categories
						</h2>
						<ul
							className='sitemapUl'
							style={{
								listStyleType: 'none',
								color: '#D28C49',
								fontSize: '18px',
							}}>
							{data.allWpCategory.nodes.map((tag: any) => (
								<li key={tag.id}>
									<a href={`/blog/${tag.slug}`}>{tag.name}</a>
								</li>
							))}
						</ul>
					</section>

					<section>
						<h2
							style={{
								fontSize: '16px',
								fontWeight: '500',
								paddingBottom: '8px',
								paddingTop: '20px',
							}}>
							Blog Posts
						</h2>
						<ul
							className='sitemapUl'
							style={{
								listStyleType: 'none',
								color: '#D28C49',
								fontSize: '18px',
							}}>
							{data.allWpPost.nodes.map((post: any) => (
								<li key={post.id}>
									<a href={`/blog/${post.slug}`}>{post.title}</a>
								</li>
							))}
						</ul>
					</section>
				</ul>
			</Container>
		</Layout>
	);
};

export default SitemapData;
